import { Button } from '@/components/ui/button';
import React from 'react';

function LoginButton({
  text,
  className,
}: {
  text: string;
  className?: string;
}) {
  return (
    <a href="https://app.lolu.ai">
      <Button className={className}>{text}</Button>
    </a>
  );
}

export default LoginButton;
