import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from '@/components/ui/navigation-menu';
import React, { ReactNode } from 'react';
import LoginButton from '@/components/LoginButton';
import { Button } from '@/components/ui/button';

interface AppSection {
  name: string;
  section: string;
  node: ReactNode;
}

function NavBar({ sections }: { sections: AppSection[] }) {
  return (
    <div className={'w-full dark:bg-black sticky top-0 bg-white z-50'}>
      <div className={'flex flex-row py-8 px-16'}>
        <div className={'basis-1/4 flex'}>
          <Logo />
        </div>
        <div className={'basis-1/2 flex justify-center dark:text-white'}>
          <NavigationMenu className="hidden md:flex">
            <NavigationMenuList>
              {sections.map((item) => {
                return (
                  <NavigationMenuItem key={item.section}>
                    <a href={'#' + item.section}>
                      <NavigationMenuLink
                        className={navigationMenuTriggerStyle()}
                      >
                        <p>{item.name}</p>
                      </NavigationMenuLink>
                    </a>
                  </NavigationMenuItem>
                );
              })}
            </NavigationMenuList>
          </NavigationMenu>
        </div>
        <div className={'basis-1/4 flex justify-end'}>
          <Button
            variant={'ghost'}
            className="mr-5 hidden md:block"
            onClick={(e) => {
              window.location.href = 'mailto:contact@lolu.ai';
              e.preventDefault();
            }}
          >
            Contact
          </Button>
          <LoginButton text={'Sign up'} />
        </div>
      </div>
    </div>
  );
}

function Logo() {
  return (
    <a className={'flex'} href={'/'}>
      <p className={'text-lg content-center dark:text-white font-semibold'}>
        Lolu.AI
      </p>
    </a>
  );
}

export default NavBar;
