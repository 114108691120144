import React, { ReactNode, useEffect } from 'react';
import './scroll-reveal.css';

const ScrollReveal = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    let debounceTimeout: any;

    const handleScroll = () => {
      clearTimeout(debounceTimeout);

      debounceTimeout = setTimeout(() => {
        const revealElements = document.querySelectorAll('.scroll-reveal');
        const windowHeight = window.innerHeight;

        revealElements.forEach((element) => {
          const elementTop = element.getBoundingClientRect().top;
          const isRevealed = element.getAttribute('data-revealed');

          if (elementTop < windowHeight - 100 && !isRevealed) {
            element.classList.add('visible');
            element.setAttribute('data-revealed', 'true');
          }
        });
      }, 10);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('load', handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('load', handleScroll);
      clearTimeout(debounceTimeout);
    };
  }, []);

  return <div className="scroll-reveal-container">{children}</div>;
};

export default ScrollReveal;
