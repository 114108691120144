import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import Lolu from '@/components/Lolu';
import { ReactNode } from 'react';
import NavBar from '@/components/NavBar';
import Demo from '@/components/Demo';
import ScrollReveal from '@/components/ScrollReveal';

interface AppSection {
  name: string;
  section: string;
  node: ReactNode;
}

const sections: AppSection[] = [
  {
    name: 'To Lolu',
    section: '',
    node: <Lolu />,
  },
  {
    name: 'Demo',
    section: 'demo',
    node: <Demo />,
  },
  // {
  //     name: 'Features',
  //     section: "features",
  //     node: <Features />,
  // },
  // {
  //     name: 'Pricing',
  //     section: "pricing",
  //     node: <Pricing />,
  // },
];

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <>
      <NavBar sections={sections}></NavBar>
      <ScrollReveal>
        {sections.map((section) => {
          return (
            <section
              id={section.section}
              className="scroll-reveal"
              key={section.section}
            >
              {section.node}
            </section>
          );
        })}
      </ScrollReveal>
    </>
  </React.StrictMode>
);
