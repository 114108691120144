import { AlertCircle } from 'lucide-react';

import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';

import { cn } from '@/lib/utils';

function AlertDemo({
  alertText,
  className,
}: {
  alertText: string;
  className?: string;
}) {
  return (
    <Alert
      variant="default"
      className="absolute bottom-20 right-1 z-10 h-20 w-fit text-xs md:text-sm"
    >
      <AlertCircle className={cn(className, 'h-4 w-4')} />
      <AlertTitle>Error</AlertTitle>
      <AlertDescription className="text-xs md:text-sm">
        {alertText}
      </AlertDescription>
    </Alert>
  );
}

export default AlertDemo;
