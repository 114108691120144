function Lolu() {
  return (
    <div className="flex flex-col pt-10 lg:pt-20 pb-20">
      <div className="flex justify-center pb-8">
        <img alt={'Lolu'} src="/lolu.png" className="w-4/12"></img>
      </div>
      <div className="flex justify-center">
        <h1 className="scroll-m-20 text-lg md:text-3xl lg:text-5xl font-extrabold tracking-tight w-1/2 text-center lg:w-2/5">
          Lolu: your virtual salesperson and customer support for your business
          powered by AI
        </h1>
      </div>
    </div>
  );
}

export default Lolu;
