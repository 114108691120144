import { z } from 'zod';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import LoginButton from '@/components/LoginButton';
import axios from 'axios';
import { useRef, useState } from 'react';
import AlertDemo from '@/components/Alert.';

const formSchema = z.object({
  name: z
    .string()
    .min(2, 'Must contain at least 2 characters')
    .max(50, 'Must contain maximum 50 characters'),
  phone: z
      .string()
      .transform((value: string) => {
        // If the input is a 10-digit number (assumed to be US), prepend +1
        if (value.length === 10 && /^\d{10}$/.test(value)) {
          return `+1${value}`;
        }
        return value;
      })
      .refine((value) => {
        const phoneNumber = parsePhoneNumberFromString(value, 'US');
        return phoneNumber && phoneNumber.isValid();
      }, {
        message: "Invalid phone number",
      }),
});

function Demo() {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
    },
  });

  const defaultButtonText = 'Talk to Lolu';

  const [alertVisible, setAlertVisible] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [buttonText, setButtonText] = useState(defaultButtonText);
  const [isSending, setIsSending] = useState(false);

  const [formIsSent, setFormIsSent] = useState(false);

  function onSubmit(formData: z.infer<typeof formSchema>) {
    const apiUrl = process.env.REACT_APP_API_URL || '';

    setIsSending(true);
    setButtonText('Sending request to Lolu...');

    axios
      .post(apiUrl, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setFormIsSent(true);
      })
      .catch((err) => {
        setFormIsSent(true);
        showAlert();
      })
      .finally(() => {
        setIsSending(false);
        setButtonText(defaultButtonText);
      });
  }

  function showAlert() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setAlertVisible(true);

    timeoutRef.current = setTimeout(() => {
      setAlertVisible(false);
    }, 5000);
  }

  return (
    <div className="flex justify-center">
      <div className="flex pb-32 lg:pb-40 items-center flex-col md:flex-row md:pt-10 lg:pt-40 lg:w-4/6">
        <div className="w-full basis-1/2 flex flex-col items-center pb-10">
          <p className="w-2/3 text-center leading-5 md:leading-7 [&:not(:first-child)]:mt-6 pb-10 text-xs md:text-sm lg:text-lg">
            Lolu AI is your virtual AI salesperson and customer support for your
            business, offering a pay-as-you-go model. It can receive calls from
            your customers, and make calls to them as well. It's marketing
            capabilities will drive your business to help staying in touch with
            your customers.
          </p>
          <LoginButton text={'Sign up to try'} />
        </div>
        <div className="basis-1/2 md:px-6 pt-10 md:pd-0 flex justify-center">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-4 md:space-y-8 lg:space-y-12 border rounded-xl p-6 lg:p-14"
            >
              <FormDescription>
                <p className="scroll-m-20 pb-2 text-black first:mt-0 text-base md:text-xl lg:text-2xl font-semibold">
                  Leave your number, <br /> and Lolu will reach out to you
                </p>
              </FormDescription>
              {formIsSent ? (
                <div className="flex items-center h-16">
                  <img
                    src="/lolu.png"
                    width="25"
                    height="25"
                    alt="Lolu small"
                  />
                  <p className="ml-2">Lolu will call you in a moment...</p>
                </div>
              ) : (
                <>
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-xs md:text-sm lg:text-base">
                          Name
                        </FormLabel>
                        <FormControl>
                          <Input
                            className="text-xs lg:text-sm"
                            placeholder="Name"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage className="text-xs" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="phone"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-xs md:text-sm lg:text-base">
                          Phone
                        </FormLabel>
                        <FormControl>
                          <Input
                            className="text-xs lg:text-sm"
                            placeholder="Phone"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage className="text-xs" />
                      </FormItem>
                    )}
                  />
                  <div className="pt-4 md:p-0">
                    <Button
                      type="submit"
                      variant={'outline'}
                      disabled={isSending}
                    >
                      {buttonText}
                    </Button>
                  </div>
                </>
              )}
            </form>
          </Form>
        </div>
      </div>
      {alertVisible && (
        <AlertDemo alertText="Unable to reach out to Lolu... Try after a while" />
      )}
    </div>
  );
}

export default Demo;
